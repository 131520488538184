import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  static targets = ["actionBar", "selectionText", "selectedIds"]
  static values = { multiPage: Boolean, allIds: Array }

  // Lifecycle

  initialize() {
    super.initialize()
  }

  connect() {
    super.connect()
    this.updateSelection()
    this.shiftKeyEvent()
  }

  // Actions

  toggleSelectAllInFilter(e) {
    // toggle the value
    this.useAllIdsValue = !this.useAllIdsValue

    // disable/enable checkboxes
    this.checkboxAllTarget.checked = this.useAllIdsValue
    this.checkboxAllTarget.disabled = this.useAllIdsValue
    this.checkboxTargets.forEach((checkbox) => checkbox.disabled = this.useAllIdsValue)

    // simulate change event for stimulus-checkbox-all integration
    const event = new Event('change', { bubbles: false, cancelable: true })
    this.checkboxAllTarget.dispatchEvent(event)
  }

  // Extend stimulus-checkbox-all functions

  toggle(e) {
    super.toggle(e)
    this.updateSelection()
  }

  refresh() {
    super.refresh()
    this.updateSelection()
  }

  // Other functions

  updateSelection() {
    this.toggleVisibility()
    this.updateSelectionText()
    this.updateFormInputs()
  }

  updateSelectionText() {
    if (this.multiPageValue && this.allSelected()) {
      this.selectionTextTarget.innerHTML = `
        All ${this.selectedIds().length} records in filter selected
        <a class='btn-link ml-2' data-toggle=link data-action='bulk-actions#toggleSelectAllInFilter'>undo</a>
      `
    } else if (this.multiPageValue) {
      this.selectionTextTarget.innerHTML = `
        ${this.selectedIds().length} selected
        <a class='btn-link ml-2' data-toggle=link data-action='bulk-actions#toggleSelectAllInFilter'>click to select all records in filter</a>
      `
    } else {
      this.selectionTextTarget.innerHTML = `
        ${this.selectedIds().length} selected
      `
    }
  }

  toggleVisibility() {
    this.actionBarTarget.classList.toggle('action-bar--selected', !this.noneSelected())
  }

  updateFormInputs() {
    this.selectedIdsTargets.forEach(input => input.value = JSON.stringify(this.selectedIds()))
  }

  selectedIds() {
    if (this.allSelected()) {
      return this.allIdsValue
    } else if (this.someSelected()) {
      return this.checked.map(checkbox => checkbox.value)
    } else {
      return []
    }
  }

  noneSelected() {
    return this.checked.length == 0
  }

  someSelected() {
    return this.checked.length > 0
  }

  allSelected() {
    return this.useAllIdsValue
  }

  selectCheckboxesBetween(e){
    if (!this.shiftKey || e.type === 'input') return

    const currentChecked = this.checkboxTargets.indexOf(e.target)
    const lastChecked = this.checkboxTargets.findLastIndex(checkbox => checkbox.checked && checkbox !== e.target)
    const start = Math.min(lastChecked, currentChecked)
    const end = Math.max(lastChecked, currentChecked)
    this.checkboxTargets.slice(start, end + 1).forEach(checkbox => checkbox.checked = true)
    this.refresh()
  }

  shiftKeyEvent() {
    ["keydown", "keyup"].forEach(event => addEventListener(event, (e) => {
      if (e.key === 'Shift') this.shiftKey = event === 'keydown'
    }))
  }
}
