import { Controller } from "@hotwired/stimulus";
import { isElementOverflowing } from '../utils';

export default class extends Controller {
  static targets = ['container'];
  static values = {
    expanded: { type: Boolean, default: false }
  }
  connect() {
    this.observeVisibility();
  }

  observeVisibility() {
    // This allows us to test for restricted height when the container becomes visible (ie modals)
    const observer = new MutationObserver(() => {
      if (this.isVisible(this.containerTarget)) {
        this.restrictHeight();
        observer.disconnect(); // Stop observing once the container is visible
      }
    });

    observer.observe(this.containerTarget, { attributes: true, childList: true, subtree: true });
  }

  isVisible(element) {
    return element.offsetWidth > 0 && element.offsetHeight > 0;
  }

  restrictHeight() {
    if (!this.hasContainerTarget) { return }

    if (isElementOverflowing(this.containerTarget)) {
      this.containerTarget.classList.add('restrict-height')
    }

    if (this.expandedValue) {
      this.expand();
    }
  }

  expand(event = null) {
    if (event) { event.preventDefault() }
    this.containerTarget.classList.add('restrict-height--expanded');
    this.dispatch('expand');
  }

  collapse(event = null) {
    if (event) { event.preventDefault() }
    this.containerTarget.classList.remove('restrict-height--expanded');
    this.dispatch('collapse');
  }

  toggle(event) {
    this.containerTarget.classList.contains('restrict-height--expanded') ? this.expand(event) : this.collapse(event);
  }
}
