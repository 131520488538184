'use strict';
import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = { submitOnSort: { type: Boolean, default: false } }
  static targets = ['form', 'position']

  connect() {
    this.sortable = Sortable.create(this.element, {
      forceFallback: true,
      draggable: '.draggable-item',
      handle: '.drag-handle',
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const form = this.formTargets.find(element => event.item.contains(element))
    this.positionTargets.forEach((element, index) => element.value = index + 1)
    if (form) form.requestSubmit()
    this.submitOnSort()
  }

  submitOnSort() {
    if (!this.submitOnSortValue) return

    this.formTargets.forEach(form => form.requestSubmit())
  }
}
