import { Controller } from "@hotwired/stimulus";
import { Sortable } from '@shopify/draggable';

export default class extends Controller {
  static targets = ["list"]

  connect() {
    const sortable = new Sortable(this.listTarget, {
      draggable: '.address-type-sort-item',
      handle: '.drag-handle',
      mirror: {
        constrainDimensions: true,
        appendTo: 'body',
      },
    });

    sortable.on('sortable:stop', () => {
      this.setOrder();
    })
  }

  removeItem(e) {
    e.preventDefault();
    $(e.currentTarget).parents('.address-type-sort-item').remove();
    this.setOrder();
  }

  setOrder() {
    const order = $('.address-type-sort-item:not(.draggable-mirror):not(.draggable--original)').map (function() {
      return $(this).data('addressType');
    });

    $("input[name='selected_address_types']").val(order.get().join());
  };
}
