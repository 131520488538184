import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "keyword", "highlightCount", "nextBtn", "prevBtn", "showBtn", "uiControl"]
  // TODO: there is alot of duplication between this and document_search_controller.js in terms of detecting mark elements, and showing and cycling through the highlights
  // we should extract a highlightable controller (to replace the 'textTarget') that both controllers can connect to via stimulus outlets.

  connect() {
    if (!this.hasUiControlTarget) { return }

    this.update()
  }

  setKeyword(event) {
    // wait 100ms before updating the mark elements
    setTimeout(() => {
      this.update()
      if (this.activeKeywords.length > 0) {
        this.next()
      }
    }, 100)
  }

  private

  update() {
    this.currentMark = 0
    this.updateMarkElements()
    this.updateControlUI()
  }

  get activeKeywords() {
    if (!this.hasKeywordTarget) { return [] }

    // return an array of values for the keyword taget buttons that are pressed (aria-pressed = true)
    return Array.from(this.keywordTargets).filter((keyword) => keyword.getAttribute('aria-pressed') === 'true').map((keyword) => keyword.value.toLowerCase())
  }

  updateMarkElements() {
    let allMarkElements = this.textTarget.querySelectorAll(`mark`)
    // activeMarkElements returns all marks where the inner text is included in the activeKeywords() array, or if activeKeywords() is empty, return all marks
    let activeMarkElements = Array.from(allMarkElements).filter((mark) => this.activeKeywords.includes(mark.innerText.toLowerCase()) || this.activeKeywords.length === 0)

    // remove all outlines, and add mark-hidden class to elements that aren't active
    allMarkElements.forEach((mark) => {
      mark.classList.remove('outlined')
      mark.classList.toggle('mark-hidden', !activeMarkElements.includes(mark))
    })

    this.markElements = activeMarkElements
  }

  updateControlUI() {
    // when using keyword targets, don't show the control UI if there are no active keywords
    if (this.hasKeywordTarget && this.activeKeywords.length == 0) {
      this.uiControlTarget.classList.add('invisible')
      this.highlightCountTarget.innerText = ``
    } else {
      this.uiControlTarget.classList.remove('invisible')
      this.highlightCountTarget.innerText = `${this.currentMark}/${this.markElements.length}`
    }
  }

  next() {
    if (this.currentMark < this.markElements.length) {
      this.currentMark++
      this.showHighlight()
      this.updateControlUI()
    }
  }

  previous() {
    if (this.currentMark > 0) {
      this.currentMark--
      this.showHighlight()
      this.updateControlUI()
    }
  }

  showHighlight() {
    let activeMark = this.markElements[this.currentMark - 1];

    this.markElements.forEach((mark) => {
      mark.classList.toggle('outlined', mark == activeMark)
    })

    activeMark?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
  }
}
